.menu__wrap {
  nav {
    border-top: 1px solid rgba(255,255,255,.2);
  }

   nav ul {
     display: flex;
     animation-delay: 0.9s;
     justify-content: flex-end;
     list-style: none;
     margin: 25px 0;
     padding: 0;
     line-height: 1.2;

     li {
       margin: 0 16px;

       &:first-child {
         margin-left: 0;
       }

       a {
         color: #dadada;
         font-size: 16px;
         border-bottom: 1px solid transparent;
         text-transform: uppercase;
         text-decoration: none;

         &:hover {
           color: $white;
           border-color: $white;
         }
       }

       &.subapp {
         flex-grow: 1;
         text-align: right;
         margin: 0;
       }

       &.subapp a {
         border-radius: 2px;
         background-color: rgb(210, 160, 16);
         box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.43);
         height: 45px;
         padding: 10px 20px;
         color: #fff;
         border: 0;
         font-weight: 600;
         font-style: italic;

         &:hover {
           border: 0;
           background: #f3b914;
         }
       }
     }
   }
}

@media (max-width: 1024px) {
  .menu__wrap nav ul {
    justify-content: center;
  }
  .subapp {
    display: none;
  }
  .header .header__burger {
    display: block;

    button.burger {
      display: block;
      position: fixed;
      animation-delay: .6s;
      top: 47px;
      right: 32px;
      outline: none;
      background-color: transparent;
      border: none;
      appearance: none;
      z-index: 1600;
    }

    button.burger span.box {
      display: block;
      position: absolute;
      width: 26px;
      height: 26px;
      margin: auto;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      cursor: pointer;
      transition: .3s;
    }

    button.burger span.box span.bar {
      right: 0;
      width: 100%;
      height: 3px;
      background-color: #fff;
      position: absolute;
      transition: .5s;
      border-radius: 3px;
    }

    button.burger span.box span.bar.top {
      top: 4px;
    }

    button.burger span.box span.bar.middle {
      top: 50%;
      margin: -1px 0 0;
    }

    button.burger span.box span.bar.bottom {
      bottom: 3px;
    }

    button.burger.active span.box span.bar {
      width: 100% !important;
      background-color: #fff;
    }

    button.burger.active span.box span.bar.top {
      -webkit-transform: rotate(45deg) translate(8px, 7px);
      -ms-transform: rotate(45deg) translate(8px, 7px);
      transform: rotate(45deg) translate(8px, 7px);
      top: 0;
    }

    button.burger.active span.box span.bar.middle {
      opacity: 0;
    }

    button.burger.active span.box span.bar.bottom {
      -webkit-transform: rotate(-45deg) translate(8px, -7px);
      -ms-transform: rotate(-45deg) translate(8px, -7px);
      transform: rotate(-45deg) translate(8px, -7px);
      bottom: 2px;
    }
  }

  .menu__wrap nav ul {
    flex-direction: column;
    height: 100%;
    text-align: center;
    margin: 0;
  }
  .menu__wrap nav ul li {
    margin: 20px 0;
  }
  .menu__wrap nav ul li a {
    font-size: 24px;
    margin: 20px 0;
  }
  .menu__wrap nav {
    position: fixed;
    top: 0;
    right: -100%;
    background-color: rgba(0, 0, 0, 0.9);
    width: 100%;
    height: 100%;
    box-shadow: 0 0 3px rgba(42, 42, 42, 0.1);
    display: block;
    z-index: 1500;
    transition: 0.7s cubic-bezier(0.86, 0, 0.07, 1);
  }

  .menu__wrap nav.active {
    right: 0;
    display: block;
  }
}

@media (max-width:550px) {
  .menu__wrap nav ul li a {
    font-size: 19px;
    margin: 10px 0;
  }
  .menu__wrap nav ul li {
    margin: 10px 0;
  }
}

@media (max-width:450px) {
  .header .header__burger button.burger {
    top: 39px;
  }
}