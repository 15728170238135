.about {
  background: url("../img/aboutbg.jpg") right center no-repeat;
  padding: 230px 0;

  .about__left {
    width: 60%;
  }

  span {
    color: $yellow;
  }
}

@media (max-width:1710px) {
  .about {
    background: url(../img/aboutbg.jpg) 120% center no-repeat;
  }
}

@media (max-width:1510px) {
  .about {
    background: url(../img/aboutbg.jpg) 140% center no-repeat;
  }
}

@media (max-width:1330px) {
  .about {
    background: url(../img/aboutbg.jpg) 180% center no-repeat;
  }
}
@media (max-width:1210px) {
  .about {
    background: url(../img/aboutbg.jpg) 220% center no-repeat;
  }
}
@media (max-width:1120px) {
  .about {
    padding: 100px 0;
    background: none;
  }
  .about .about__left {
    width: 100%;
  }
}

@media (max-width:610px) {
  .about {
    padding: 80px 0;
  }
}

