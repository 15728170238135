.header {
  height: 100vh;
  color: #fff;
  position: relative;
  z-index: 10;
  animation-delay: 0.3s;
  background: url("../img/headerbg.jpg") center center no-repeat;
  background-size: cover;
   .header__wrap {
     display: flex;
     justify-content: space-between;
     align-items: center;
     padding-top: 20px;
     padding-bottom: 20px;

     .header__logo {
       width: 209px;
       animation-delay: 0.3s;

       & img {
         width: 100%;
       }
     }
   }
  .title__wrap {
    padding-top: 45px;
    font-weight: 100;
    display: flex;
    justify-content: space-between;

    .title__text {
      flex-grow: 1;
    }
    .title__calc {
      width: 380px;
      flex: 0 0 380px;
      animation-delay: 1.5s;
    }

    p {
      margin: 0;
      font-size: 22px;
      animation-delay: 1.6s;
      line-height: 1.364;
    }

    h1 {
      font-size: 60px;
      animation-delay: 1.3s;
      font-weight: 800;
      font-style: italic;
      line-height: 64px;
      text-transform: uppercase;

      span {
        display: block;
        font-size: 65px;
      }
    }
  }
  a.mouse {
    position: absolute;
    width: 22px;
    animation-delay: 1.7s;
    height: 42px;
    bottom: 40px;
    left: 50%;
    margin-left: -12px;
    border-radius: 15px;
    border: 2px solid #fff;
    -webkit-animation: intro 1s;
    animation: intro 1s;
  }
  .scroll {
    display: block;
    width: 3px;
    height: 3px;
    margin: 6px auto;
    border-radius: 4px;
    background: #fff;
    -webkit-animation: finger 1s infinite;
    animation: finger 1s infinite;
  }
  @-webkit-keyframes intro {
    0% {
      opacity: 0;
      -webkit-transform: translateY(40px);
      transform: translateY(40px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
  @keyframes intro {
    0% {
      opacity: 0;
      -webkit-transform: translateY(40px);
      transform: translateY(40px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
  @-webkit-keyframes finger {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      -webkit-transform: translateY(20px);
      transform: translateY(20px);
    }
  }
  @keyframes finger {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      -webkit-transform: translateY(20px);
      transform: translateY(20px);
    }
  }

  .form-search {
    background: rgba(16, 35, 57, 0.9);
    padding:10px 20px 20px;

    h4 {
      margin: 0 0 15px 0;
      text-align: center;
      font-weight: 300;
    }

    .input-append {
      position: relative;
    }

    .input-append > span{
      color:#fff;
      font-size: 14px;
    }

    input[type=text], select {
      width: 100%;
      border: 1px solid #134889;
      background: rgb(17, 25, 36);
    }

    ul.typeahead.dropdown-menu {
      position: absolute;
      list-style: none;
      margin: 0;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
      width: 100%;
      z-index: 1500;
      padding: 0;
    }

    ul.typeahead.dropdown-menu li a {
      padding: 10px;
      border-bottom: 1px solid #303542;
      background: #373d4c;
      font-weight: 400;
      font-size: 13px;
      color: #fff;
      text-decoration: none;
      display: block;
    }

    ul.typeahead.dropdown-menu li:last-child a {
      border-bottom: 0;
    }

    ul.typeahead.dropdown-menu li.active a {
      background: #1f202e;
    }

    ul.typeahead.dropdown-menu li a strong {
      color: #00a6ff;
      font-weight: 400;
    }
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: rgba(255, 255, 255, 0.7);
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: rgba(255, 255, 255, 0.7);
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
      color: rgba(255, 255, 255, 0.7);
    }
  }
}

.header__phone {
  text-align: right;
  animation-delay: 0.6s;

  &.header__adress {
    text-align: left;
    background: url("../img/marker.png") left center no-repeat;
    padding-left: 40px;
    line-height: 20px;
  }

  .header__number a {
    font-size: 35px;
    color: #fff;
    line-height: 28px;
    text-decoration: none;
    font-weight: 700;
    display: flex;

    span {
      font-size: 20px;
      font-weight: 300;
      margin-top: -3px;
      margin-right: 6px;
    }

    &:hover {
      color: darken(#fff, 20%);
    }
  }
  .header__callme a, .header__lookmap a {
    color: #abd5ff;
    text-decoration: none;
    border-bottom: 1px solid;
    text-transform: uppercase;
    font-size: 12px;
    &:hover {
      border-color:transparent ;
    }
  }
}

.header .header__burger, .header .subapp2 {
  display: none;
}

@media (max-width: 1024px) {
  .header .subapp2 {
    display: inline-block;
    position: absolute;
    animation-delay: 1.5s;
    right: 15px;
    top: 104px;
  }
  .header .subapp2 a {
    border-radius: 2px;
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none;
    background-color: rgb(210, 160, 16);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.43);
    height: 45px;
    padding: 8px 15px;
    color: #fff;
    border: 0;
    font-weight: 600;
    font-style: italic;

    &:hover {
      border: 0;
      background: #f3b914;
    }
  }
}

@media (max-width: 991px) {
  .header__adress {
    display: none;
  }
  .header .title__wrap h1 {
    font-size: 48px;
    -webkit-animation-delay: 1.3s;
    animation-delay: 1.3s;
    font-weight: 700;
    line-height: 50px;
  }
  .header .title__wrap h1 span {
    font-size: 52px;
  }
  .header .title__wrap p {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .header .title__wrap h1 {
    font-size: 38px;
    line-height: 41px;
  }
  .header .title__wrap h1 span {
    font-size: 41px;
  }
  .header .title__wrap .title__text {
    width: 100%;
    text-align: center;
  }
  .header .title__wrap .title__calc {
    display: none;
  }
}


@media (max-width: 610px) {
  .header .header__wrap {
    flex-direction: column;
  }
  .header .header__wrap .header__logo {
    margin-bottom: 15px;
  }
  .header__phone {
    text-align: center;
  }
  .header .title__wrap {
    top: 63%;
    font-weight: 300;
  }

  .header .header__wrap .header__logo {
    width: 110px;
    align-self: flex-start;
  }

  .header .title__wrap p {
    font-size: 16px;
  }

  .header__phone .header__number a {
    font-size: 25px;
  }
  .header__phone .header__callme a {
    font-size: 10px;
  }
  .header__phone .header__callme {
    line-height: 13px;
    text-align: right;
  }
  .header__phone .header__number a span {
    font-size: 14px;
  }
  .header .header__wrap {
    align-items: end;
  }
}

@media (max-width: 450px) {
  .header .subapp2 {
    display: inline-block;
    position: absolute;
    right: 50%;
    transform: translate(50%, 0);
    top: 154px;
  }
  .header .header__wrap {
    align-items: center;
  }
  .header__phone .header__callme {
    text-align: center;
  }
  .header .title__wrap h1 {
    font-size: 31px;
    line-height: 33px;
  }
  .header .title__wrap h1 span {
    font-size: 33px;
  }
}