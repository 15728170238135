.stats {
  background: url("../img/countbg.jpg") center center no-repeat;
  background-size: cover;
  padding: 70px 0;

  .stats__blocks {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    color: #fff;

    .stats__element {
      width: 31%;

      p {
        font-size: 15px;
        line-height: 24px;
      }

      .stats__number {
        line-height: 36px;

        span {
          line-height: 0;
        }
      }

      .stats__top {
        font-size: 50px;
        position: relative;
        margin-bottom: 32px;

        &::after {
          content: '';
          width: 190px;
          height: 2px;
          margin-top: 10px;
          display: block;
          background: $yellow;
        }

        &::before {
          content: '';
          position: absolute;
          bottom: -10px;
          width: 96px;
          height: 2px;
          display: block;
          background: $yellow;
        }

        span {
          font-size: 30px;

          &.stats__bt {
            font-size: 18px;
            display: block;
          }
        }
      }
    }
  }
}

@media (max-width:810px) {
  .stats .stats__blocks .stats__element {
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 25px 0 10px 0;

    &:last-child {
      border: 0;
    }
  }
}

