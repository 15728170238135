.advantages {
  padding: 100px 0;

  .f__block {
    margin:0 auto;
    text-align: center;
  }

  .advantages__blocks {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(290px, auto));
    grid-column-gap: 30px;
    padding-top: 65px;
    padding-bottom: 50px;

    .advantages__element {
      position: relative;
      overflow: hidden;
      border-radius: 5px;
      box-shadow: 0 5px 14px #8c99a9;
      margin-bottom: 30px;
      height: 370px;

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: cover;
      }

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(9, 33, 61, 0.4);
      }
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to bottom,rgba(9,33,61,.3) 0%,rgba(9,33,61,.8) 60%,rgba(9,33,61,.99) 90%);
      }

      .advantages__title {
        position: absolute;
        bottom: 40px;
        left: 0;
        padding: 0 30px;
        color: #fff;
        z-index: 100;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 19px;
        line-height: 28px;
      }
    }
  }
}

@media (max-width:510px) {
  .advantages .f__block img {
    width: 100%;
  }
  .advantages .advantages__blocks .advantages__element {
    -webkit-box-shadow: 0 4px 9px #8c99a9;
    box-shadow: 0 4px 9px #8c99a9;
    height: 270px;
  }
  .advantages {
    padding: 100px 0 50px;
  }
}