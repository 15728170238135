.letters {
  padding: 100px 0;

  .letters__block {
    margin: 50px -20px 0;

    img {
      height: 380px;
      width: 100%;
      object-fit: contain;

    }

    .slick-slide {
      margin: 0 20px;
      padding: 20px 0;
      outline: none;

      a {
        position: relative;
        border: 2px solid $yellow;
        display: block;
        border-radius: 5px;
        box-shadow: 0 4px 10px #d2d7de;

        &:hover {
          box-shadow: 0 4px 10px rgba(210, 215, 222, 0);
        }

        &:hover:before, &:hover:after {
          opacity: 1;
        }

        &:before {
          content: '';
          background: url(../img/zoom.svg) center center no-repeat;
          position: absolute;
          left: 50%;
          top: 50%;
          opacity: 0;
          transform: translate(-50%, -50%);
          padding: 5px;
          width: 30px;
          transition:  opacity 0.2s ease-out;
          height: 30px;
          z-index: 10;
        }
        &:after {
          content: '';
          opacity: 0;
          background: rgba(0, 128, 255, 0.6);
          transition:  opacity 0.2s ease-out;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    .slick-dots li button:before {
      font-size: 22px;
    }
    .slick-dots li.slick-active button:before {
      color: $yellow;
      opacity: 1;
    }

    & .slick-dots li button:focus:before, & .slick-dots li button:hover:before {
      opacity: 0.7;
      color: $yellow;
    }
    & .slick-dots li.slick-active button:focus:before, & .slick-dots li.slick-active button:hover:before {
      color: $yellow;
      opacity: 1;
    }
  }
}

@media (max-width:560px) {
  .letters .letters__block img {
    height: auto;
  }
}