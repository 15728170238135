@charset "UTF-8";

/**
 * Основные стили
 **/

* {
	box-sizing: border-box;
}

html {
	font-size: 18px;
}

body {
	font-family: 'Exo 2', sans-serif;
	font-weight: 400;
	overflow-wrap: break-word;
	word-wrap: break-word;
	background: $bg;
	line-height: 1.667;
	font-size: 18px;

	&.over_hide {
		overflow: hidden;
	}
}

.text-center {
	text-align: center;
}

h2 {
	font-size: 40px;
	font-weight: 700;
	font-style: italic;
	text-transform: uppercase;
	line-height: 60px;
	margin: 0 0 30px 0;
	position: relative;

	&::after {
		content: '';
		width: 190px;
		height: 2px;
		display: block;
		background: $yellow;
	}

	&::before {
		content: '';
		position: absolute;
		bottom: -10px;
		width: 96px;
		height: 2px;
		display: block;
		background: $yellow;
	}

	&.text-center {
		text-align: center;

		&::after {
			margin: 0 auto;
		}
	}
}

a {
	transition: all .2s ease;
}

#form__r {
	display: none;
}

.container {
	@include min-max(320px, 100%, 0);
	overflow: hidden;
}

.wrap {
	@include min-max(320px, 1200px, 15px);
}

.wrap__s {
	@include min-max(320px, 600px, 15px);
}

@media (max-width: 810px) {
	h2 {
		font-size: 32px;
		line-height: 60px;
	}
}

@media (max-width: 610px) {

	html {
		font-size: 15px;
	}
	h2 {
		font-size: 25px;
		line-height: 27px;
	}
	h2::after, h2::before {
		margin-top: 9px;
	}
}