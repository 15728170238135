.send__form {
  background: url("../img/mapbg.jpg") center center no-repeat;
  background-size: cover;
  padding: 180px 0;
  color: #fff;

  .wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .send__left {
    font-size: 41px;
    line-height: 50px;
    text-transform: uppercase;
    font-weight: 700;
    font-style: italic;
    width: 60%;

    .send__left__count {
      font-size: 105px;
      line-height: 100px;
      color: $yellow;
      font-weight: 700;
      font-style: italic;
    }
  }
  .send__right {
    width: 38%;
  }
}

@media (max-width:910px) {
  .send__form .send__left {
    font-size: 28px;
    line-height: 37px;
    width: 50%;
  }
  .send__form .send__right {
    width: 48%;
  }
}

@media (max-width:768px) {
  .send__form {
    padding: 80px 0;
  }
  .send__form .send__left {
    font-size: 35px;
    line-height: 40px;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }
  .send__form .send__right {
    width: 100%;
    margin: 0 auto;
  }
}

@media (max-width:510px) {
  .send__form .send__left {
    font-size: 20px;
    line-height: 30px;
  }
  .send__form .send__right {
    width: 100%;
    margin: 0 auto;
  }
  .send__form .send__left .send__left__count {
    font-size: 90px;
    line-height: 90px;
  }
}