.contact {
  padding: 180px 0;
  background: url("../img/contact.jpg") center center;

  .contact__block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .contact__left {
      width: 48%;

      .contact__element {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        border-bottom: 1px solid #eaeaea;
        padding: 20px 0 20px 50px;
        position: relative;

        &:last-child {
          border: 0;
        }

        .contact__b {
          font-size: 20px;
          font-weight: 700;
        }
        img {
          position: absolute;
          left: 0;
          top:50%;
          transform: translate(0, -50%);
          height: 40px;
          width: 40px;
        }

        &.сontact__phone img {
          transform: translate(0, -50%) rotate(25deg);
        }
      }
    }
    .contact__right {
      width: 48%;

      a.yamapa {
        display: block;
        height: 400px;

        img {
          object-fit: cover;
          width: 100%;
          height: 400px;
        }
      }
    }
  }
}

@media (max-width:910px) {
  .contact .contact__block .contact__left, .contact .contact__block .contact__right {
    width: 100%;
    margin-bottom: 10px;
  }
  .contact {
    padding: 80px 0;
  }
}

@media (max-width:510px) {
  .contact .contact__block .contact__left .contact__element .contact__b {
    font-size: 17px;
    font-weight: 700;
  }
  .contact .contact__block .contact__left .contact__element img {
    height: 30px;
    width: 30px;
  }
}