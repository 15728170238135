.form__block {
  position: relative;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 36px 24px;

  .form__element.ff__span {
    color: #a6a6a6;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px solid rgb(30, 41, 53);
    padding: 5px 0;

    &.ff__span__l {
      border-bottom: 0;
      margin-bottom: 10px;
    }
  }

  input[type="text"], textarea, select {
    border: 1px solid #434343;
    background: rgba(42, 42, 42, 0.7);
    border-radius: 3px;
    padding:12px 10px;
    outline: none;
    color: #fff;
    width: 100%;
    font-size: 14px;
    margin-bottom: 20px;
  }
  textarea {
    height: 100px;
  }

  input[type="button"] {
    background: #0d5195;
    border-radius: 5px;
    border: 0;
    cursor: pointer;
    padding: 15px 0;
    text-align: center;
    color: #fff;
    outline: none;
    text-transform: uppercase;
    width: 100%;
    position: relative;
    font-size: 18px;
    font-weight: 700;
    transition: all .2s ease;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.39),inset 0 1px 0 rgb(66, 160, 255);

    &:active {
      top:1px;
    }


    &:hover {
      background: $yellow;
    }
  }

  input {
    width: 100%;
  }
}
.messages-block {
  height: 100%;
  width: 100%;

  & .susses {
    color: #ffffff;
    text-align: center;
    font-size: 17px;
    line-height: 33px;
    background: rgb(93, 154, 57);
    border-radius: 3px;
    margin-bottom: 5px;
    width: 100%;
    z-index: 200;
    left: 0;
    top: 0;
    position: absolute;
    height: 100%;
    display: flex;
    justify-content: center;
    font-weight: 100;
    padding: 6px 15px;
    align-items: center;
  }

  & .error {
    color: #c7c7c7;
    font-size: 13px;
    background: #863838;
    border-radius: 3px;
    margin-bottom: 5px;
    width: 100%;
    display: block;
    position: relative;
    font-weight: 300;
    padding: 1px 15px;
  }
}


@media (max-width:510px) {
  .form__block {
    border-radius: 5px;
    padding: 25px 15px;
  }
}