.reviews {
  background: url("../img/reviewsbg.jpg") center center no-repeat;
  background-size: cover;
  padding: 70px 0;
  color: #fff;

  .reviews__block {
    margin: 50px -20px 0;
  }

  h2 {
    color: #fff;
  }

  .slick-slide {
    margin: 0 20px;
    padding: 20px 0;
    outline: none;

    .reviews__title {
      text-transform: uppercase;
      font-size: 20px;
      font-weight: 700;
      position: relative;
      font-style: italic;

      &::after {
        content: '';
        width: 110px;
        height: 2px;
        margin-top: 3px;
        display: block;
        background: $yellow;
      }
    }

    .reviews__comment {
      font-size: 15px;
      line-height: 24px;
      margin-top: 15px;
      color: #b7c4d2;
    }
  }

  .slick-dots li button:before {
    font-size: 22px;
  }
  .slick-dots li.slick-active button:before {
    color: $yellow;
    opacity: 1;
  }

  & .slick-dots li button:focus:before, & .slick-dots li button:hover:before, & .slick-next:focus:before, & .slick-next:hover:before, & .slick-prev:focus:before, & .slick-prev:hover:before {
    opacity: 0.7;
    color: #fff;
  }
  & .slick-dots li.slick-active button:focus:before, & .slick-dots li.slick-active button:hover:before {
    color: $yellow;
    opacity: 1;
  }
  .slick-dots li button:before {
    color: #fff;
  }
}

@media (max-width:560px) {
  .letters .letters__block, .reviews .reviews__block {
    margin: 0;
  }
  .letters .letters__block .slick-slide, .reviews .slick-slide {
    margin: 0;
  }
}